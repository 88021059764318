<template>
  <div class="teacher">
    <list-template
        :loading="loading"
        :total="total"
        :table-data="tableData"
        :table-config="tableConfig"
        :search-config="searchConfig"
        :current-page="page"
        @onSearch="onSearch"
        @onReset="onReset"
        @onChangePage="changeCurrentPage"
        @onHandle="tableHandle"
    ></list-template>

    <el-dialog
        :visible.sync="dialogVisibleTo"
        width="30%"
        v-if="dialogVisibleTo"
    >
      <el-form :model="data" status-icon :rules="rules" ref="ruleForm" label-width="150rem" class="demo-ruleForm">
        <el-form-item :label="type+'原因'" prop="type">
          <el-select v-model="data.type" :placeholder="'请选择'+type+'原因'">
            <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="type+'原因备注'">
          <el-input
              type="textarea"
              :placeholder="'请输入'+type+'原因'"
              v-model="data.remarks"
              maxlength="50"
              show-word-limit
          >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
			<el-button type="primary" @click="submitForm('ruleForm')">继续{{ type }}</el-button>
      <el-button @click="dialogVisibleTo = false">点错了</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {system_reset_pwd} from '@/api'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading:true,
      searchConfig: [
        {
          prop: 'name',
          placeholder: '搜索姓名',
        },
        {
          prop: 'students_no',
          placeholder: '搜索学号',
        },
        {
          prop: 'grade',
          placeholder: '筛选年级',
          tag: 'select',
          options: [],
          change: (item) => {
            this.$_axios.get(`/site/class-data`, {params: {grade: item}}).then(res => {
              this.searchConfig[3].options = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/class_name/g, 'label'));
            });
          },
        },
        {
          prop: 'class_data_id',
          placeholder: '筛选班级',
          tag: 'select',
          options: [],
        },
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'students_no',
          label: '学号',
        },
        {
          prop: 'name',
          label: '姓名 ',
        },
        {
          prop: 'school_name',
          label: '校区',
        },
        {
          prop: 'grade',
          label: '年级',
        },
        {
          prop: 'class_data_id',
          label: '行政班班级',
        },
        {
          label: '操作',
          handle: true,
          width: 180,
          render: row => {
            let arr = ['编辑']
            arr.push(row.status === 1 ? '禁用' : '启用')
            arr.push('重置密码')
            return arr;
          },
        },
      ],
      search: null,
      total: 0,
      dialogVisible: false,
      // 表格中的数据
      tableData: [],
      dialogVisibleTo: false,
      rules: {
        type: [
          {required: true, message: '请选择原因', trigger: 'change'},
        ],
      },
      type:"禁用",
      options: [],
      disableOptions:[],
      enableOptions:[],
      data: {
        id: '',
        state: '',
        remarks: '',
        type: '',
      },
      temp: null,

    };
  },
  computed: {
    ...mapState(['page']),
  },
  mounted() {
    this.$store.commit('setPage', 1);
    this.getData();
  },
  activated() {
    this.getData();
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e);
      this.getData();
    },
    /**
     * 获取数据
     */
    getData() {
      let {search, page} = this;
      this.loading = true;
      this.$_axios2.get('api/account-student/student/get-list?roles=1', {
        params: {...search, page},
      }).then(res => {
        let {data} = res.data;
        this.tableData = data.list;
        this.total = data.page.total;
      }).finally(()=>this.loading = false);
    },
    // 搜索按钮被点击
    onSearch(val) {
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData();
    },
    // 表格后面操作被点击
    tableHandle(row, handle, index) {
      if (handle.type === "edit") {
        this.$router.push({
          path: './edit',
          query: {id: row.id},
        });
      } else if (handle.type === "resetPassword") {
        this.$confirm('<div>是否确认重置密码?</div><div>重置后，密码将重置为学生身份证后6位！</div>', '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true
              system_reset_pwd({id: row.id,student_no: row.students_no}).then(() => {
                this.$message({ type: 'success', message: '重置成功!' })
                done()
              }).finally(() => {
                instance.confirmButtonLoading = false
              })
            } else {
              done()
            }
          }
        })
      } else {
        this.dialogVisibleTo = true;
        this.data.type = null;
        this.data.remarks = '';
        this.data.id = row.id;
        this.temp = row;
        if (handle.type === "disable") {
          this.data.state = 0;
          this.type = "禁用";
          this.options = this.disableOptions;
        } else {
          this.type = "启用";
          this.data.state = 1;
          this.options = this.enableOptions;
        }
      }
    },
    // 重置按钮被点击
    onReset() {
      this.$_axios.get('site/class-data').then(res => {
        this.searchConfig[3].options = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/class_name/g, 'label'));
      });
      this.search = null;
      this.getData();
    },
    //	确认按钮被点击
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$_axios2.post('api/account-student/student/state', this.data).then(res => {
            if (res.data.status === 0) {
              this.dialogVisibleTo = false;
              this.temp.status = this.temp.status == 1 ? 0 : 1;
            }
          }).finally(() => this.getData());
        }
      });
    },
  },
  created() {
    this.$_axios.get('site/class-data').then(res => {
      this.searchConfig[3].options = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/class_name/g, 'label'));
    });
    this.$_axios.get('site/grades').then(res => {
      this.searchConfig[2].options = JSON.parse(JSON.stringify(res.data.data).replace(/id/g, 'value').replace(/name/g, 'label'));
    });
    this.$_axios2.get('api/account-student/student/option').then(res => {
      this.disableOptions = res.data.data;
    });
    this.$_axios2.get('api/account-student/student/open-option').then(res => {
      this.enableOptions = res.data.data;
    });
  },
};
</script>

<style scoped lang="scss">
.list {
  padding: 24rem 0 0 32rem;

  .search {
    .el-input,
    .el-select {
      width: 240rem;
      margin-right: 24rem;
    }
  }

  .pagination {
    margin-top: 50rem;
  }
}

::v-deep .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  content: '*';
  color: #fff;
  margin-right: 4rem;
}

</style>
